import React from 'react';
import './styles.css';

function App() {
  return (
    <div className="App">
      <header className="header">
        <div className="logo-container">
          <img src={`${process.env.PUBLIC_URL}/tangent_circle.svg`} alt="Tangent Icon" className="tangent-icon" />
          <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Tangentic Logo" className="logo" />
        </div>
      </header>
      <main className="main-content">
        <section className="mission-section">
          <h2>Mission</h2>
          <p>
            Our mission is to make AI trustworthy. We help companies deploy AI agents that are reliable, accurate, and secure.
          </p>
        </section>
        <section className="contact-section">
          <h2>Contact</h2>
          <p>If you'd like to learn more about how Tangentic can help your organization, we'd love to hear from you. </p>
          <p>Reach out at <a href="mailto:info@tangentic.ai">info@tangentic.ai</a></p>
        </section>
      </main>
    </div>
  );
}

export default App;